body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Work Sans', 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Dr Sugiyama';
  src: local('assets/fonts/DrSugiyama-Regular'), url(./assets/fonts/DrSugiyama-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Indie Flower';
  src: local('assets/fonts/IndieFlower-Regular'), url(./assets/fonts/IndieFlower-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'La Belle Aurore';
  src: local('assets/fonts/LaBelleAurore-Regular'), url(./assets/fonts/LaBelleAurore-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Swanky and MooMoo';
  src: local('assets/fonts/SwankyandMooMoo-Regular'), url(./assets/fonts/SwankyandMooMoo-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Zeyada';
  src: local('assets/fonts/Zeyada-Regular'), url(./assets/fonts/Zeyada-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Rock Salt';
  src: local('assets/fonts/RockSalt-Regular'), url(./assets/fonts/RockSalt-Regular.ttf) format('truetype');
}